import { observer } from 'mobx-react';

import {
  FormLabel,
  Text,
  VStack,
  Divider,
  Box,
  BoxProps,
  HStack,
} from '@chakra-ui/react';

import { Caption } from '../../../components/Typography/Typography';
import { useCustomerGroups } from '../../../hooks/useStores';
import { Label } from '../../../components/Typography/Typography';
import { PricingTiersTable, PricingTiersTableRow } from './PricingTiersTable';
import FormFieldWrapper from '../../../components/Form/FormFieldWrapper';
import PricingTiersEmptyMessage from './PricingTiersEmptyMessage';
import ObjectLink from '../../../components/ObjectLink/ObjectLink';
import CustomerPricingField from './CustomerPricingField';

const ShowPricingTable = ({ product }: any) => {
  const { pricingTiersGroupSet } = useCustomerGroups();

  return (
    <>
      <Divider />
      <VStack align="left" spacing={6}>
        {pricingTiersGroupSet?.groups?.length == 0 && (
          <FormFieldWrapper
            fieldLabel="Price"
            fieldName="price"
            subLabel="Optional"
          >
            <Text>
              {product.price && product.pricing_unit ? (
                <>
                  ${Number(product.price).toFixed(2)} /{' '}
                  {product.pricing_unit && product.pricing_unit.name}
                </>
              ) : (
                <>-</>
              )}
            </Text>
          </FormFieldWrapper>
        )}

        {pricingTiersGroupSet?.groups?.length > 0 ? (
          <>
            <Label>Pricing</Label>
            <FormFieldWrapper fieldLabel="Unit" fieldName="unit">
              <Text>{product.pricing_unit?.name || '-'}</Text>
            </FormFieldWrapper>
            <VStack align="left" spacing={2}>
              <FormLabel fontSize="sm">Price by pricing tier</FormLabel>
              <PricingTiersTable
                rows={[
                  <ShowPricingTierRow
                    key={`pricing-row-unassigned`}
                    href={`/pricing-tiers/customer-groups/unassigned`}
                    name="Default"
                    price={product.price}
                    unitName={product.pricing_unit?.name}
                    customerCount={
                      pricingTiersGroupSet._unassignedCustomerCount
                    }
                  />,
                  ...product.pricing_tiers?.map((tier: any, i: number) => {
                    const group = pricingTiersGroupSet?.groups?.find(
                      (group: any) => group.id === tier.id,
                    );
                    return (
                      <ShowPricingTierRow
                        key={`pricing-row-${tier.id}`}
                        href={`/pricing-tiers/customer-groups/${tier.id}`}
                        name={tier.name}
                        containerProps={
                          i % 2 == 0 ? { backgroundColor: 'gray.50' } : {}
                        }
                        price={tier.price || product.price}
                        priceIsPlaceholder={!tier.price}
                        unitName={product.pricing_unit?.name}
                        customerCount={group?.customerCount}
                      />
                    );
                  }),
                ]}
              />

              <Caption color="gray.500">All prices are GST exclusive</Caption>
            </VStack>
          </>
        ) : (
          <PricingTiersEmptyMessage />
        )}

        <CustomerPricingField product={product} isEditable={false} />
      </VStack>
    </>
  );
};

const ShowPricingTierRow = ({
  name,
  href,
  price,
  priceIsPlaceholder = false,
  unitName,
  customerCount,
  containerProps = {},
}: {
  name: string;
  href: string;
  price?: string;
  priceIsPlaceholder?: boolean;
  unitName: string;
  customerCount?: number;
  containerProps?: BoxProps;
}) => (
  <PricingTiersTableRow {...containerProps}>
    <Box>
      <Box>
        <HStack>
          <Text fontSize="15px">{name}</Text>

          <ObjectLink name={name} href={href} />
        </HStack>
        <Text fontSize="13px" color="gray.500">
          {customerCount} customers
        </Text>
      </Box>
    </Box>
    <Text fontSize="15px" color={priceIsPlaceholder ? 'gray.400' : 'gray.700'}>
      {price ? (
        <>
          $ {Number(price).toFixed(2)} / {unitName}
        </>
      ) : (
        <>-</>
      )}
    </Text>
  </PricingTiersTableRow>
);

export default observer(ShowPricingTable);
