import { findIndex } from 'lodash';
import { toJS } from 'mobx';
import { cast, types, Instance } from 'mobx-state-tree';
import { v4 as uuid4 } from 'uuid';
import PriceTier from './PriceTier';
import CustomerPrice from './CustomerPrice';
import SourceCategoryProduct from './SourceCategoryProduct';
import Unit from './Unit';
import UnitSetting from './UnitSetting';
import AccountingProduct from './AccountingProduct';

export const getMinUnits = (units: any) => {
  // For some reason this didn't work as an action on the model.
  return units
    ? units
        .map((unit: any) => {
          if (unit.min_order_quantity) {
            return {
              quantity: unit.min_order_quantity,
              unit_name: unit.name,
            };
          }
        })
        .filter(Boolean)
    : [];
};

const Product = types
  .model('Product', {
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    photo_large_url: types.maybeNull(types.string),
    photo_thumb_url: types.maybeNull(types.string),
    photo_url: types.maybeNull(types.string),
    photo_base64: types.maybeNull(types.string),
    photo_destroy: types.maybeNull(types.boolean),
    active: true,
    visible_to_customers: true,
    price: types.maybeNull(types.string),
    favourite: types.maybeNull(types.boolean),
    product_code: types.maybeNull(types.string),
    lead_time_hours: 0,
    wholesale_supplier: types.maybeNull(types.string),
    company_ids: types.array(types.number),
    customer_group_ids: types.array(types.number),
    category: types.maybeNull(
      types.model({
        id: types.number,
        name: types.string,
      }),
    ),
    unit: types.maybeNull(Unit),
    pricing_unit: types.maybeNull(Unit),
    units: types.array(Unit),
    unit_settings: types.array(UnitSetting),
    sourceCategories: types.array(SourceCategoryProduct),
    isCurrentProduct: false,
    isChecked: false,
    isValid: types.maybeNull(types.boolean),
    fieldErrors: types.array(
      types.model({
        fieldName: types.string,
        title: types.maybeNull(types.string),
        message: types.string,
      }),
    ),
    is_synced: types.maybeNull(types.boolean),
    accounting_product: types.maybeNull(AccountingProduct),
    pricing_tiers: types.maybeNull(types.array(PriceTier)),
    customer_prices: types.maybeNull(types.array(CustomerPrice)),
  })
  .views((self) => ({
    get pricingFormState() {
      return {
        pricing_tiers: self.pricing_tiers || [],
        customer_prices: self.customer_prices || [],
      };
    },
    get formState() {
      let tmpFormState: any = {
          name: self.name,
          price: self.price,
          unit_id: self.unit?.id,
          category_id: self.category?.id,
          active: self.active,
          visible_to_customers: self.visible_to_customers,
          description: self.description,
          pricing_unit_id: self.pricing_unit?.id,
          product_code: self.product_code,
          lead_time_hours: self.lead_time_hours,
          wholesale_supplier: self.wholesale_supplier,
          unit_ids: self.units.map((unit) => unit.id),
          unit_settings_attributes: self.unit_settings.map((us) => us.formState),
          company_ids: self.company_ids,
          customer_group_ids: self.customer_group_ids,
          source_category_products_attributes: self.sourceCategories.map(
            (pcp) => pcp.formState,
          ),
          photo_base64: self.photo_base64,
      }
      if (self.photo_destroy) {
        tmpFormState.photo_destroy = self.photo_destroy;
      }
      return tmpFormState;
    },
    get checkedUnits() {
      return self.units.map((u) => u.id);
    },
    get minUnitQuantities() {
      return self.units.map((unit: any) => {
        if (unit.min_order_quantity) {
          return {
            quantity: unit.min_order_quantity,
            unit_name: unit.name,
          };
        }
      }).filter(Boolean);
    },
    get defaultUnit() {
      return self.units.find((unit: any) => unit.is_default) || self.unit;
    },
    get pricingUnit() {
      return self.units.find((unit: any) => unit.is_pricing_unit) || self.pricing_unit;
    },
    get minUnits() {
      return [];
    },
    get hasPhoto() {
      return !!self.photo_url || !!self.photo_base64;
    },
    get numCustomerPrices() {
      if (self.customer_prices) {
        return self.customer_prices.filter((cp: Instance<typeof CustomerPrice>) => cp.price != null && cp.price != "").length
      } else {
        return 0;
      }
    }
  }))
  .actions((self) => ({
    addUnitSetting(unit_id: number, min_order_quantity: string | null) {
      const newUnitSetting = UnitSetting.create({
        id: null,
        unit_id: unit_id,
        min_order_quantity: min_order_quantity,
      })
      self.unit_settings.push(newUnitSetting);
      return newUnitSetting;
    },
    setName(name: string) {
      self.name = name;
    },
    setActive(active: boolean) {
      self.active = active;
    },
    setDescription(description: string) {
      self.description = description;
    },
    setCategory(category: any) {
      self.category = toJS(category);
    },
    setIsCurrentProduct(isCurrent: boolean) {
      self.isCurrentProduct = isCurrent;
    },
    toggleChecked() {
      self.isChecked = !self.isChecked;
    },
    setIsChecked(isChecked: boolean) {
      self.isChecked = isChecked;
    },
    setUnit(unit: any) {
      self.unit = toJS(unit);
    },
    setPhotoBase64(photo_base64: string) {
      self.photo_base64 = photo_base64;
    },
    setPhotoDestroy() {
      self.photo_destroy = true;
      self.photo_base64 = null;
      self.photo_url = null;
    },
    toggleUnit(unit: any) {
      let isChecked = true;
      const index = findIndex(self.units, (u) => u.id === unit.id);

      if (index > -1) {
        isChecked = false;
        self.units.splice(index, 1);

        if (self.units.length > 0) {
          self.unit = toJS(self.units[0]);
        } else {
          self.unit = null;
        }
      } else {
        const newUnit = toJS(unit);
        self.units.push(newUnit);

        if (!self.unit) {
          self.unit = toJS(newUnit);
        }
      }
    },
    setProductCode(code: string) {
      self.product_code = code;
    },
    setPrice(price: string) {
      self.price = price;
    },
    setPricingUnit(unit: any) {
      self.pricing_unit = unit;
    },
    setHideFromCustomers(value: boolean) {
      self.visible_to_customers = !value;
    },
    setVisbileToCustomers(value: boolean) {
      self.visible_to_customers = value;
    },
    setLeadTimeHours(value: number) {
      self.lead_time_hours = value;
    },
    setWholesaleSupplier(supplier: string) {
      self.wholesale_supplier = supplier;
    },
    setCompanyIds(companyIds: (number | string)[]) {
      let companyNumIds = companyIds.map(Number);
      self.company_ids = cast(companyNumIds);
    },
    setCustomerGroupIds(groupIds: (number | string)[]) {
      let groupNumIds = groupIds.map(Number);
      self.customer_group_ids = cast(groupNumIds);
    },
    setFieldErrors(errors: any) {
      self.fieldErrors = errors;
    },
    addSourceCategory() {
      self.sourceCategories.push(SourceCategoryProduct.create({ id: uuid4() }));
    },
    setFavourite(value: boolean) {
      self.favourite = value
    },
    addPricingTiers(pricingTiers: any) {
      self.pricing_tiers = pricingTiers;
    },
    addCustomerPrices(customerPrices: any) {
      self.customer_prices = customerPrices.map((cp: { customer_id: number, price: string | null }) => CustomerPrice.create(cp));
    },
    updateData(product: any) {
      self.name = product.name;
      self.photo_url = product.photo_url;
      self.photo_large_url = product.photo_large_url;
      self.photo_thumb_url = product.photo_thumb_url;
      self.description = product.description;
      self.active = product.active;
      self.visible_to_customers = product.visible_to_customers;
      self.price = product.price;
      self.favourite = product.favourite;
      self.product_code = product.product_code;
      self.lead_time_hours = product.lead_time_hours;
      self.wholesale_supplier = product.wholesale_supplier;
      self.company_ids = product.company_ids;
      self.customer_group_ids = product.customer_group_ids;
      self.category = product.category;
      self.unit = product.unit;
      self.pricing_unit = product.pricing_unit;
      self.units = product.units;
      self.unit_settings = product.unit_settings.map((us: any) =>
        UnitSetting.create(us),
      );
      self.is_synced = product.is_synced;
      self.accounting_product = product.accounting_product;
      self.sourceCategories = product.source_category_products.map((pcp: any) =>
        SourceCategoryProduct.create(pcp),
      );
    },
  }))
  .actions((self) => ({
    updateCustomerPrice(customerId: number, price: string | null) {
      const customerPrice = self.customer_prices?.find((cp: any) => cp.customer_id === customerId);
      if (customerPrice) {
        customerPrice.setPrice(price);
      } else {
        if (price === null) { return }

        if (self.customer_prices === null) {
          self.addCustomerPrices([{ customer_id: customerId, price: price }])
        } else {
          self.customer_prices.push(CustomerPrice.create({ customer_id: customerId, price: price }));
        }
      }
    },
    validate(categories: any) {
      let isValid = true;
      let newFieldErrors = [];

      if (!self.category?.id && self.active) {
        newFieldErrors.push({
          fieldName: 'category_id',
          message: 'Category is a required field.',
        });
      }

      if (!self.name) {
        newFieldErrors.push({
          fieldName: 'name',
          message: 'Name is a required field.',
        });
      }

      if (self.price && !self.pricing_unit?.id) {
        newFieldErrors.push({
          fieldName: 'pricing_unit_id',
          message: 'A pricing unit is required if you set a price.',
        });
      }

      const nonNullOrEmptyTierPrices = self.pricing_tiers ? self.pricing_tiers.map((pt: any) => pt.price).filter((price: string) => !!price) : [];
      if (!self.price && nonNullOrEmptyTierPrices.length > 0) {
        newFieldErrors.push({
          fieldName: 'price',
          title: 'Default price missing',
          message: 'Enter a default price. Alternatively, remove all other price(s) below to show no prices to any customer.',
        });
      }

      if (!self.unit?.id) {
        newFieldErrors.push({
          fieldName: 'unit_id',
          message: 'Allowed unit(s) is a required field.',
        });
      }

      if (self.description && self.description.length > 255) {
        newFieldErrors.push({
          fieldName: 'description',
          message: 'The description must be 255 characters or less.',
        });
      }

      if (
        categories &&
        self.sourceCategories &&
        self.sourceCategories.filter((pcp) => !pcp.markedForDeletion).some((pcp) => pcp.amountsRequired(categories))
      ) {
        if (self.units.length > 1) {
          const errorCategories = self.sourceCategories
            .filter((pcp) => pcp.amountsRequired(categories))
            .map((c) =>
              categories.find((cat: any) => cat.id === c.source_category_id),
            );
          const categoryString = errorCategories.reduce(
            (a, t, i) => `${a}${i ? ', ' : ''}‘${t.name}’`,
            '',
          );
          newFieldErrors.push({
            fieldName: 'units',
            message:
              'Product can only have one unit because it is assigned to a source category which requires item sizes: ' +
              categoryString,
          });
        }
      }

      if (categories && self.sourceCategories) {
        self.sourceCategories.forEach((pcp) => {
          if (pcp._destroy !== true) {
            if (!pcp.source_category_id) {
              newFieldErrors.push({
                fieldName: `category-${pcp.id}`,
                message: 'You must select a source category.',
              });
            }
            if (
              pcp.source_category_id &&
              self.sourceCategories
                .filter((c) => c.id !== pcp.id && c._destroy !== true)
                .some((c) => c.source_category_id === pcp.source_category_id)
            ) {
              newFieldErrors.push({
                fieldName: `category-${pcp.id}`,
                message: 'You cannot select the same source category twice.',
              });
            }
            if (pcp.amountsRequired(categories)) {
              if (!pcp.amount) {
                newFieldErrors.push({
                  fieldName: `amount-${pcp.id}`,
                  message: 'Item size is required for this source category.',
                });
              }

              if (
                pcp.amount &&
                /^(\d{0,5})(\.{1}\d{0,3})?$/.test(pcp.amount) === false
              ) {
                newFieldErrors.push({
                  fieldName: `amount-${pcp.id}`,
                  message:
                    'Item size must be a number no greater than 99999.999.',
                });
              }
            }
          }
        });
      }

      if (newFieldErrors.length > 0) {
        isValid = false;
        self.setFieldErrors(newFieldErrors);
      }

      self.isValid = isValid;
    }
  }));

export default Product;
