import { makeObservable, observable, action, computed } from 'mobx';
import * as Sentry from '@sentry/browser';
import { Instance } from 'mobx-state-tree';

import { api } from '../../api';
import Product from '../../models/Product';

interface ProductsListInterface {
  [id: number]: Instance<typeof Product>;
}

export class ProductStore {
  user: any = [];

  @observable productError: boolean = false;
  @observable unitsError: boolean = false;
  @observable productsListError: boolean = false;
  @observable categoriesError: boolean = false;
  @observable sourceCategoriesError: boolean = false;

  @observable currentProductId: any;
  @observable categories: any;
  @observable units: any;
  @observable sourceCategories: any;

  @observable currentStatusFilter: string = '';
  @observable currentSearchQuery: string = '';
  @observable currentCategoryFilter: string = '';
  @observable currentPage: number = 1;

  @observable totalCount?: number;
  @observable totalPages: number = 0;
  @observable nextPage?: number;
  @observable previousPage?: number;

  @observable productsAreLoading: boolean = true;
  @observable productsList: ProductsListInterface = {};
  @observable productsIndex: (number | null)[] = [];

  constructor(user?: any) {
    this.user = user;
    makeObservable(this);
  }

  @computed
  get products() {
    return this.productsIndex.map((key) => {
      if (typeof key === 'number') return this.productsList[key];
    });
  }

  @computed
  get currentProduct(): any {
    if (this.currentProductId) {
      return this.productsList[this.currentProductId];
    }
  }

  @computed
  get currentBulkIds(): Array<number> {
    return Object.values(this.productsList)
      .filter((p) => p.isChecked)
      .map((p) => p.id);
  }

  @action setProductsListError = (productsListError: boolean) => {
    this.productsListError = productsListError;
  };
  @action setCurrentProduct = (product: any) => {
    if (!product) {
      if (this.currentProductId) {
        this.productsList[this.currentProductId].setIsCurrentProduct(false);
      }
      this.currentProductId = null;
    } else {
      if (this.currentProductId && this.productsList[this.currentProductId]) {
        this.productsList[this.currentProductId].setIsCurrentProduct(false);
      }

      let productObject = this.productsList[product.id];
      if (!productObject) {
        productObject = Product.create(product);
        this.productsList[product.id] = productObject;
      }

      productObject.updateData(product);
      productObject.setIsCurrentProduct(true);
      this.currentProductId = product.id;
    }
  };
  @action setProductError = (productError: boolean) => {
    this.productError = productError;
  };
  @action setPricingTiers = (productId: any, pricingTiers: any) => {
    let productObject = this.productsList[productId];
    productObject?.addPricingTiers(pricingTiers);
  };
  @action setCustomerPrices = (productId: any, customerPrices: any) => {
    let productObject = this.productsList[productId];
    productObject?.addCustomerPrices(customerPrices);
  };
  @action setCategories = (categories: any) => {
    this.categories = categories;
  };
  @action setCategoriesError = (categoriesError: boolean) => {
    this.categoriesError = categoriesError;
  };
  @action setUnits = (units: any) => {
    this.units = units;
  };
  @action setUnitsError = (unitsError: boolean) => {
    this.unitsError = unitsError;
  };
  @action setSourceCategories = (sourceCategories: any) => {
    this.sourceCategories = sourceCategories;
  };
  @action setSourceCategoriesError = (error: boolean) => {
    this.sourceCategoriesError = error;
  };
  @action setCurrentStatusFilter = (currentStatusFilter: string) => {
    this.currentStatusFilter = currentStatusFilter;
  };
  @action setCurrentSearchQuery = (currentSearchQuery: string) => {
    this.currentSearchQuery = currentSearchQuery;
  };
  @action setCurrentCategoryFilter = (currentCategoryFilter: string) => {
    this.currentCategoryFilter = currentCategoryFilter;
  };
  @action setCurrentProductsPage = (currentProductsPage: number) => {
    this.currentPage = currentProductsPage;
  };
  @action setCurrentBulkIds = (currentBulkIds: Array<number>) => {
    if (currentBulkIds.length > 0) {
      currentBulkIds.forEach((id) => {
        this.productsList[id].setIsChecked(true);
      });
    } else {
      Object.values(this.productsList).forEach((product) => {
        product.setIsChecked(false);
      });
    }
  };
  @action setProductsAreLoading = (productsAreLoading: boolean) => {
    this.productsAreLoading = productsAreLoading;
  };
  @action setProductsActive = (ids: number[], active: boolean) => {
    ids.forEach((id) => {
      this.productsList[id].setActive(active);
    });
  };

  @action
  setProducts = (products: any) => {
    this.totalCount = products.total_count;
    this.totalPages = products.total_pages;
    this.nextPage = products.next_page;
    this.previousPage = products.previous_page;
    this.productsIndex = [];
    products.results.forEach((product: any, index: number) => {
      if (!this.productsList[product.id]) {
        this.productsList[product.id] = Product.create(product);
      }
      this.productsIndex[index] = product.id;
    });
  };

  @action
  getProductsList = (successCallback: () => any, errorCallback: () => any) => {
    if (this.user) {
      this.setProductsAreLoading(true);
      const limit = 20;

      const url = `/v4/products?page=${this.currentPage}&limit=${limit}${
        this.currentStatusFilter && '&status=' + this.currentStatusFilter
      }${this.currentSearchQuery && '&q=' + this.currentSearchQuery}${
        this.currentCategoryFilter &&
        '&category_id=' + this.currentCategoryFilter
      }`;

      api
        .get(url)
        .then(async (response) => {
          if (response.status === 500) {
            if (errorCallback) {
              errorCallback();
            }
            this.setProductsListError(true);
          } else {
            if (response.ok) {
              const data = await response.json();
              this.setProducts(data);
              if (successCallback) {
                successCallback();
              }
              this.setProductsAreLoading(false);
              return data;
            }
          }
          this.setProductsAreLoading(false);
        })
        .catch((error) => {
          Sentry.captureException(error);
          if (errorCallback) {
            errorCallback();
          }
          return error;
        });
    }
  };

  @action
  getProduct = (productId: string, successCallback: () => any) => {
    const url = `/v4/products/${productId}`;

    api
      .get(url)
      .then(async (response) => {
        if (response.status === 500) {
          this.setProductError(true);
        } else {
          if (response.ok) {
            const data = await response.json();
            this.setCurrentProduct(data);
            if (successCallback) {
              successCallback();
            }
            return data;
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return error;
      });
  };

  @action
  getPricing = (productId: string, successCallback?: () => any) => {
    const url = `/v4/products/${productId}/prices`;

    api
      .get(url)
      .then(async (response) => {
        if (response.status === 500) {
          this.setUnitsError(true);
        } else {
          if (response.ok) {
            const data = await response.json();
            this.setPricingTiers(productId, data?.pricing_tiers);
            this.setCustomerPrices(productId, data?.customer_prices);
            if (typeof successCallback === 'function') {
              successCallback();
            }
            return data;
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return error;
      });
  };

  @action
  getCategories = (isCompanyFiltered: boolean) => {
    let url;
    if (isCompanyFiltered) {
      url = `/v2/companies/${this.user.company.id}/categories`;
    } else {
      url = `/v4/categories`;
    }

    api
      .get(url)
      .then(async (response) => {
        if (response.status === 500) {
          this.setCategoriesError(true);
        } else {
          if (response.ok) {
            const data = await response.json();
            this.setCategories(data);
            return data;
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return error;
      });
  };

  @action
  getUnits = () => {
    const url = `/v2/companies/${this.user.company.id}/units`;

    api
      .get(url)
      .then(async (response) => {
        if (response.status === 500) {
          this.setUnitsError(true);
        } else {
          if (response.ok) {
            const data = await response.json();
            this.setUnits(data);
            return data;
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        return error;
      });
  };

  @action
  getSourceCategories = () => {
    return new Promise((resolve, reject) => {
      api
        .get('/v4/source_categories/summary')
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.setSourceCategories(data);
            resolve(data);
          }
          this.setSourceCategoriesError(true);
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  @action
  updateProduct = (formData: any, productId: number) => {
    const url = `/v4/products/${productId}`;

    return new Promise((resolve, reject) => {
      api
        .put(
          url,
          JSON.stringify({
            product: {
              ...formData,
            },
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.productsList[productId].updateData(data);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  @action
  updatePricing = (formData: any, productId: number) => {
    const url = `/v4/products/${productId}/prices`;

    return new Promise((resolve, reject) => {
      api
        .put(
          url,
          JSON.stringify({
            pricing_tiers: formData.pricing_tiers,
            customer_prices: formData.customer_prices,
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.ok) {
            this.productsList[productId]?.addPricingTiers(data.pricing_tiers);
            this.productsList[productId]?.addCustomerPrices(data.customer_prices);
            resolve(data);
          }
          reject(data);
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  @action
  createProduct = (formData: any) => {
    return new Promise((resolve, reject) => {
      api
        .post(
          '/v4/products',
          JSON.stringify({
            ...formData,
          }),
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            reject(data);
          } else {
            if (response.ok) {
              resolve(data);
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  @action
  deleteBatchProducts = (
    productIds: any,
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    api
      .post(
        '/v4/products/batch_delete',
        JSON.stringify({
          product_ids: productIds,
        }),
      )
      .then(async (response) => {
        const data = await response;
        if (response.status !== 200) {
          if (errorCallback) {
            errorCallback();
          }
        } else {
          if (response.ok) {
            if (successCallback) {
              successCallback();
            }
            return { data };
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (errorCallback) {
          errorCallback();
        }
        return error;
      });
  };

  @action
  updateBatchProducts = (
    updatedData: any,
    successCallback: () => any,
    errorCallback: () => any,
  ) => {
    api
      .post(
        '/v4/products/batch_update',
        JSON.stringify({
          ...updatedData,
        }),
      )
      .then(async (response) => {
        const data = await response;
        if (response.status !== 200) {
          if (errorCallback) {
            errorCallback();
          }
        } else {
          if (response.ok) {
            if (successCallback) {
              successCallback();
            }
            return { data };
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (errorCallback) {
          errorCallback();
        }
        return error;
      });
  };

  @action
  clearFilters = () => {
    this.setCurrentStatusFilter('');
    this.setCurrentCategoryFilter('');
    this.setCurrentSearchQuery('');
    this.setCurrentProductsPage(1);
  };
}

export default new ProductStore();
