import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { useCustomerGroups, useProducts } from '../../../hooks/useStores';

import LoadingDetail from '../../../components/LoadingDetail/LoadingDetail';
import EmptyDetail from '../../../components/EmptyDetail/EmptyDetail';
import ShowProduct from './ShowProduct';
import NewProduct from './NewProduct';
import EditProduct from './EditProduct';

type ProductDetailProps = {
  productId: any;
};

const ProductDetail = ({ productId }: ProductDetailProps): JSX.Element => {
  const {
    currentProduct,
    units,
    getUnits,
    getProduct,
    getPricing,
    setCurrentProduct,
  } = useProducts();
  const { getCustomerGroupSets } = useCustomerGroups();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (!units) {
      // Only load the units once.
      getUnits();
    }
    getCustomerGroupSets();

    if (productId) {
      getProduct(productId, () => {
        getPricing(productId, () => {
          setLoading(false);
        });
      });
    } else {
      setCurrentProduct(null);
      setLoading(false);
    }
  }, [productId]);

  return loading ? (
    <LoadingDetail />
  ) : (
    <Switch>
      <Route path="/products/new">
        <NewProduct />
      </Route>
      <Route path="/products/:productId/edit">
        {currentProduct && <EditProduct product={currentProduct} />}
      </Route>
      <Route path="/products/:productId">
        {currentProduct && <ShowProduct product={currentProduct} />}
      </Route>
      <Route path="/products">
        <EmptyDetail />
      </Route>
    </Switch>
  );
};

export default observer(ProductDetail);
