import { types } from 'mobx-state-tree';

const CustomerPrice = types
  .model('CustomerPrice', {
    customer_id: types.number,
    price: types.maybeNull(types.string)
  })
  .actions((self) => ({
    setCustomerId(newId: number) {
      self.customer_id = newId;
    },
    setPrice(newPrice: string | null) {
      self.price = newPrice;
    },
  }));

export default CustomerPrice;
