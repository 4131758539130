import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useCurrentCompany } from '../../../contexts/currentCompany';

import {
  HStack,
  Text,
  Avatar,
  Box,
  Button as ChakraButton,
  useDisclosure,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spacer,
  FormControl,
  Switch,
  FormLabel,
  VStack,
} from '@chakra-ui/react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { PriceInput } from './PricingForm';
import { Caption } from '../../../components/Typography/Typography';
import { Instance } from 'mobx-state-tree';
import Product from '../../../models/Product';
import { CaretRightIcon, UsersIcon } from '../../../components/Icons/Icons';
import CustomerPrice from '../../../models/CustomerPrice';
import { pluralizeString } from '../../../utils';
import ModalFooterActions from '../../../components/Modal/ModalFooterActions';

type DraftCustomerPrice = {
  customer_id: number;
  price: string | null;
};

const CustomerPricingField = ({
  product,
  isEditable = false,
}: {
  product: Instance<typeof Product>;
  isEditable?: boolean;
}) => {
  const { customerPricing } = useFlags();
  const { customers, getCustomers } = useCurrentCompany();
  const {
    isOpen: isCustomerPricingOpen,
    onOpen: onCustomerPricingOpen,
    onClose: onCustomerPricingClose,
  } = useDisclosure();

  useEffect(() => {
    getCustomers(
      () => {},
      () => {},
    );
  }, []);

  if (!customerPricing) {
    return <></>;
  }

  return (
    <>
      <VStack align="left" spacing={2}>
        <FormLabel fontSize="sm">Price by customer</FormLabel>

        <ChakraButton
          variant="outline"
          color="gray.300"
          width="full"
          onClick={onCustomerPricingOpen}
          rightIcon={<CaretRightIcon color="gray.700" />}
          justifyContent="space-between"
          w="300px"
        >
          <HStack color="gray.700">
            <UsersIcon />
            <Text>
              {product.numCustomerPrices} customer{' '}
              {pluralizeString('price', product.numCustomerPrices || 0)}
            </Text>
          </HStack>
        </ChakraButton>
        <Caption mt="2" color="gray.500">
          Set a price on a per-customer basis. This will be used in place of any
          applicable prices in the above table.
        </Caption>
      </VStack>

      {customers && customers.length > 0 && (
        <CustomerPriceModal
          isOpen={isCustomerPricingOpen}
          onClose={onCustomerPricingClose}
          product={product}
          customers={customers}
          customerPrices={product.customer_prices}
          isEditable={isEditable}
        />
      )}
    </>
  );
};

const CustomerRow = observer(
  ({
    isEditable,
    product,
    customer,
    customerPrice,
    setCustomerPrice,
  }: {
    isEditable: boolean;
    product: Instance<typeof Product>;
    customer: any;
    customerPrice: DraftCustomerPrice | null;
    setCustomerPrice: (price: string) => void;
  }) => {
    if (customerPrice === null) {
      return <></>;
    }

    return (
      <HStack
        justify="space-between"
        boxShadow="inset 0px -1px 0px #E5E7EB"
        px={6}
        py={3}
      >
        <Box w="100%">
          <Box
            w="100%"
            flex={1}
            display="flex"
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack w="100%">
              <Avatar size="sm" src={customer.logo_url || ''}></Avatar>
              <Text color="gray.700" fontWeight="400" noOfLines={2}>
                {customer.name}
              </Text>

              <Spacer />

              {isEditable ? (
                <Box w="212px" ml="16px">
                  <PriceInput
                    price={customerPrice?.price || ''}
                    unitName={product.pricing_unit?.name || ''}
                    setPrice={setCustomerPrice}
                  />
                </Box>
              ) : (
                <Text>
                  {customerPrice.price ? (
                    <>
                      ${Number(customerPrice.price).toFixed(2)} /{' '}
                      {product.pricing_unit && product.pricing_unit.name}
                    </>
                  ) : (
                    <>-</>
                  )}
                </Text>
              )}
            </HStack>
          </Box>
        </Box>
      </HStack>
    );
  },
);

const CustomerPriceModal = ({
  isEditable,
  isOpen,
  onClose,
  product,
  customers,
  customerPrices,
}: any) => {
  const [draftCustomerPrices, setDraftCustomerPrices] =
    useState<DraftCustomerPrice[]>();
  const [showOnlyCustomersWithPricing, setShowOnlyCustomersWithPricing] =
    useState<boolean>(false);

  const resetDraftCustomerPrices = () => {
    let tempCustomerPrices: DraftCustomerPrice[] = [];
    customers.forEach((customer: any) => {
      let draftCustomerPrice: DraftCustomerPrice = {
        customer_id: customer.id,
        price: null,
      };
      let existingCustomerPrice = customerPrices.find(
        (cp: Instance<typeof CustomerPrice>) => cp.customer_id === customer.id,
      );

      if (existingCustomerPrice) {
        draftCustomerPrice.price = existingCustomerPrice.price;
      }

      tempCustomerPrices.push(draftCustomerPrice);
    });

    setDraftCustomerPrices(tempCustomerPrices);
  };

  useEffect(() => {
    resetDraftCustomerPrices();
  }, []);

  if (draftCustomerPrices === undefined) {
    return <></>;
  }

  const onConfirm = () => {
    draftCustomerPrices.forEach((draftCustomerPrice: DraftCustomerPrice) => {
      product.updateCustomerPrice(
        draftCustomerPrice.customer_id,
        draftCustomerPrice.price,
      );
    });

    onClose();
  };

  const draftCustomerPriceForCustomer = (customerId: number) => {
    return (
      draftCustomerPrices.find(
        (cp: DraftCustomerPrice) => cp.customer_id === customerId,
      ) || null
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} autoFocus={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          px={6}
          pb={6}
          backgroundColor="gray.100"
          borderRadius={'5px 5px 0'}
        >
          <Text mt={4}>Customer pricing</Text>

          <FormControl>
            <HStack>
              <Switch
                id="show-customer-prices"
                position="relative"
                size="sm"
                isChecked={showOnlyCustomersWithPricing}
                onChange={(e) => {
                  setShowOnlyCustomersWithPricing(e.target.checked);
                }}
              />
              <FormLabel
                fontSize="xs"
                fontWeight="500"
                htmlFor="show-customer-prices"
                cursor="pointer"
              >
                Show only customers with a custom price
              </FormLabel>
            </HStack>
          </FormControl>
        </ModalHeader>
        <ModalBody p={0}>
          {customers.length > 0 && (
            <Box maxHeight="480px" overflowY="scroll">
              {customers.map((customer: any) => {
                let draftCustomerPrice = draftCustomerPriceForCustomer(
                  customer.id,
                );
                if (
                  showOnlyCustomersWithPricing &&
                  (!draftCustomerPrice ||
                    draftCustomerPrice.price === null ||
                    draftCustomerPrice.price === '')
                ) {
                  return <></>;
                }

                return (
                  <CustomerRow
                    key={`customer-price-row-${customer.id}`}
                    product={product}
                    isEditable={isEditable}
                    customer={customer}
                    customerPrice={draftCustomerPrice}
                    setCustomerPrice={(price: string) => {
                      let newDraftCustomerPrices = draftCustomerPrices.map(
                        (cp: DraftCustomerPrice) => {
                          if (cp.customer_id === customer.id) {
                            return { ...cp, price: price };
                          } else {
                            return cp;
                          }
                        },
                      );
                      setDraftCustomerPrices(newDraftCustomerPrices);
                    }}
                  />
                );
              })}
            </Box>
          )}
        </ModalBody>

        <ModalFooterActions
          backgroundColor="gray.100"
          borderRadius={'0 0 5px 5px'}
          primaryButton={
            isEditable
              ? {
                  onClick: onConfirm,
                  tooltip:
                    'Apply your pricing changes. You still need to save the product!',
                }
              : {
                  text: 'Close',
                  onClick: onClose,
                }
          }
          secondaryButton={isEditable ? { onClick: onClose } : undefined}
        />
      </ModalContent>
    </Modal>
  );
};

export default observer(CustomerPricingField);
